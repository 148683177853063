import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
	# TODO: Check to see if all of these query fields are actually needed...
	{
		allShopifyCollection {
			edges {
				node {
					products {
						# bringing in the fragment query we created in the fragments.js file look to the new method below as this one is depreciated!
						...ShopifyProductFields
					}
					title
					description
					shopifyId
					image {
						id
						localFile {
							childImageSharp {
								gatsbyImageData(
									width: 400
									placeholder: BLURRED
									formats: [WEBP]
								)
							}
						}
					}
				}
			}
		}
		allShopifyProduct {
			edges {
				node {
					...ShopifyProductFields
				}
			}
		}
	}
`;

const defaultState = {
	products: [],
};

const ProductContext = React.createContext(defaultState);
export default ProductContext;

export function ProductContextProvider({ children }) {
	//Grabbing items from the query above
	const { allShopifyCollection, allShopifyProduct } = useStaticQuery(query);

	return (
		<ProductContext.Provider
			value={{
				products: allShopifyProduct.edges.map(({ node }) => node),
				// loading the query above into an array here whilst parsing out the nodes...
				collections: allShopifyCollection.edges.map(({ node }) => node),
			}}>
			{children}
		</ProductContext.Provider>
	);
}
