import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body{
    font-family: 'Montserrat', sans-serif;
    line-height: 1.2;
    background-color: #333333;
    color:  #EBEBEB
  }

  h1,h2,h3,h4,h5,h6{
    margin: 20px 0;
    font-family: 'Oswald', sans-serif;
    color: #EBEBEB;
  }

  h1{
    font-size: 3em;
  }

  h2{
    font-size: 2.5em;
  }

  h3{
    font-size: 2em;
  }
  h4{
    font-size: 1.5em;
  }

  h5{
    font-size: 1em;
  }
  
  h6{
    font-size: 0.75em;
  }

  .modal-content {
    background:#5B0B02;
    
  }

  .modal-open {
  padding-right: 0 !important;
}

.social-icon {
		height: 55px;
		width: 55px;
		@media (max-width: 1200px) {
			height: 40px;
			width: 40px;
		}
	}
`;
